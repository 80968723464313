import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchKPINames } from '../../api/fetchers';
import useKPIData from '../../hooks/useKPIData';

const ProjectCashFlowsChart = ({ projectId, aggregation }) => {
  const theme = useTheme();
  const [kpiNames, setKpiNames] = useState({});

  // Define the KPIs for project cash flows
  const cashFlowKPIs = ['PR_TOTREV', 'PR_TOTCST', 'PR_TOTPRF'];

  // Fetch KPI names
  useEffect(() => {
    const getKPINames = async () => {
      const names = await fetchKPINames(cashFlowKPIs);
      setKpiNames(names);
    };
    getKPINames();
  }, []);

  // Fetch data for each KPI
  const revenueData = useKPIData(projectId, 'PR_TOTREV', aggregation);
  const costData = useKPIData(projectId, 'PR_TOTCST', aggregation);
  const profitData = useKPIData(projectId, 'PR_TOTPRF', aggregation);

  const kpiDataArray = useMemo(
    () => [revenueData, costData, profitData].filter(Boolean),
    [revenueData, costData, profitData],
  );

  const { chartData, xLabels } = useMemo(() => {
    const allData = kpiDataArray
      .map(({ data }) => data)
      .filter(data => data && data.length > 0);

    if (allData.length === 0) return { chartData: [], xLabels: [] };

    // Create a set of all unique dates across all series
    const allDates = new Set(
      allData.flatMap(data => data.map(item => item.date)),
    );
    const sortedDates = Array.from(allDates).sort(
      (a, b) => new Date(a) - new Date(b),
    );

    // Create a map for each KPI with date as key and amount as value
    const kpiMaps = allData.map(data =>
      Object.fromEntries(data.map(item => [item.date, item.amount_value])),
    );

    // Create series data with 0 for missing dates
    const series = kpiMaps.map((kpiMap, index) => ({
      data: sortedDates.map(date => kpiMap[date] || 0),
      label: kpiNames[cashFlowKPIs[index]] || cashFlowKPIs[index],
    }));

    const xLabels = sortedDates.map(date =>
      new Date(date).toLocaleDateString(),
    );

    return { chartData: series, xLabels };
  }, [kpiDataArray, cashFlowKPIs, kpiNames]);

  const isLoading = kpiDataArray.some(({ loading }) => loading);
  const hasData = chartData.length > 0 && xLabels.length > 0;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!hasData) {
    return <Typography>No cash flow data available</Typography>;
  }

  const colors = [
    '#a8e6cf', // revenue (mint green)
    '#ffaaa5', // costs (soft coral)
    '#4d4d4d', // profits (dark grey)
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Project Cash Flows
      </Typography>
      <Box height={300} marginLeft={2}>
        <LineChart
          series={chartData.map((series, index) => ({
            ...series,
            color: colors[index % colors.length],
            curve: 'linear',
          }))}
          xAxis={[{ data: xLabels, scaleType: 'band' }]}
          minwidth={800}
          height={300}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              padding: 0,
            },
          }}
          sx={{ padding: '10px' }}
        />
      </Box>
    </Box>
  );
};

export default ProjectCashFlowsChart;
