export const CURRENT_VERSION = '0.9.7';
export const APP_NAME = 'Tandem Investor Platform';
export const COPYRIGHT_MSG =
  '© 2024 Skala & Tandem Group. All rights reserved.';

export const RELEASE_NOTES = [
  //   {
  //     version: '0.9.9',
  //     description:
  //       'Official beta 2-week update: Added Cash Calls and Corporate Org Chart components. Bugfixes and UI/UX improvements.',
  //     date: '2024-10-29',
  //   },
  //   {
  //     version: '0.9.8',
  //     description:
  //       'Official beta release: Introduced advanced portfolio analytics and expanded reporting capabilities.',
  //     date: '2024-10-15',
  //   },
  {
    version: '0.9.7.2',
    description:
      'Updates project dashboard display of dates and calculations of Land Development projects.',
    date: '2024-10-08',
  },
  {
    version: '0.9.7',
    description:
      'Beta pre-release (internal): Improvements to overall UI/UX, dashboards, report and upload functionality.',
    date: '2024-10-08',
  },
  {
    version: '0.9.5',
    description:
      'Alpha release with additional bugfixes regarding display of dates on project dashboard charts.',
    date: '2024-08-27',
  },
];

// New labels
export const ABOUT_MODAL_TITLE = 'About';
export const SYSTEM_INFO_COPY_TOOLTIP = 'Copy system info';
export const SYSTEM_INFO_COPIED_TOOLTIP = 'Copied!';
export const CLOSE_BUTTON_TEXT = 'Close';
export const DOCUMENTATION_LINK_TEXT = 'Documentation';
export const RELEASE_NOTES_TITLE = 'Release Notes:';
