export const equityContributionIds = [4];
export const capitalReturnIds = [5, 6, 7];

export const project_types = ['Construction Land', 'Income Producing', 'Other'];
export const project_sub_types = [
  'Construction',
  'Land',
  'Income Producing',
  'Other',
];
export const construction_statuses = [
  'Pre-Development',
  'Servicing',
  'Active',
  'Not Active',
  'Complete',
];
export const data_sources = ['Internal', 'External'];
export const user_roles = ['Admin', 'User'];
export const company_types = ['GP', 'LP'];

export const csv_data_example = [
  `
[
  {"KPI_SLUG": "PR_EQTCNT",
    "KPI_Name": "Equity Contribution",
    "Project_ID": 3,
    "2022-01-01": "633,466",
    "2022-02-01": "393,653",
    "2022-03-01": "152,881",
    ...
  },
  {"KPI_SLUG": "PR_EQTDST",
    "KPI_Name": "Equity Distribution",
    "Project_ID": 3,
    "2022-01-01": "752,466",
    "2022-02-01": "458,623",
    "2022-03-01": "981,281",
    ...
  }
]
  `,
];

export const equityContributionSlugs = ['PR_EQTCNT'];
export const capitalReturnSlugs = ['PR_EQTDST', 'PR_PFTDST', 'PR_INTDST'];

export const defaultSelectedKPIs = {
  'Income Producing': ['AM_DST', 'AM_REV', 'AM_EXP', 'AM_NCF'],
  'Construction Land': ['PR_EQTCNT', 'PR_EQTDST', 'PR_PFTDST', 'PR_INTDST'],
};

export const KPI_SLUGS = {
  PR_TOTREV: 'PR_TOTREV',
  PR_TOTCST: 'PR_TOTCST',
  PR_TOTPRF: 'PR_TOTPRF',
  PR_EQTCNT: 'PR_EQTCNT',
  PR_EQTDST: 'PR_EQTDST',
  PR_PFTDST: 'PR_PFTDST',
  PR_INTDST: 'PR_INTDST',
  PR_TCF: 'PR_TCF',
  AM_REV: 'AM_REV',
  AM_EXP: 'AM_EXP',
  AM_NOI: 'AM_NOI',
  AM_CPX: 'AM_CPX',
  AM_PRI: 'AM_PRI',
  AM_NCF: 'AM_NCF',
  AM_DST: 'AM_DST',
  AM_PMG: 'AM_PMG',
  AM_DCR: 'AM_DCR',
  AM_LTV: 'AM_LTV',
  AM_MBAL: 'AM_MBAL',
  AM_FMV: 'AM_FMV',
  AM_INVD: 'AM_INVD',
};

export const PROJECT_KPI_SLUGS = Object.keys(KPI_SLUGS).filter(slug =>
  slug.startsWith('PR_'),
);
export const ASSET_MANAGEMENT_KPI_SLUGS = Object.keys(KPI_SLUGS).filter(slug =>
  slug.startsWith('AM_'),
);

export const INVESTMENT_METRIC_SLUGS = [
  'PR_EQTCNT',
  'PR_EQTDST',
  'PR_PFTDST',
  'PR_INTDST',
];
