import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { api } from '../api/fetchers';
import { login_labels } from '../labels/login_labels';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const handleSubmit = async e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError(login_labels.PASSWORDS_DO_NOT_MATCH);
      return;
    }
    try {
      await api.resetPassword(token, newPassword);
      navigate('/login', {
        state: { message: login_labels.PASSWORD_RESET_SUCCESS },
      });
    } catch (error) {
      setError(login_labels.PASSWORD_RESET_ERROR);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-indigo-300 dark:bg-gray-800">
      <div className="bg-white dark:bg-gray-900 p-10 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="mb-5 text-2xl font-bold text-center text-gray-800 dark:text-gray-200">
          {login_labels.RESET_PASSWORD}
        </h1>
        {error && <div className="text-red-600 text-center mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="newPassword"
              className="block text-gray-700 dark:text-gray-300 font-bold mb-2"
            >
              {login_labels.NEW_PASSWORD_LABEL}
            </label>
            <input
              type="password"
              id="newPassword"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 dark:text-gray-300 font-bold mb-2"
            >
              {login_labels.CONFIRM_PASSWORD_LABEL}
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
          >
            {login_labels.RESET_PASSWORD_BUTTON}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
