export const login_labels = {
  LOGIN_TITLE: 'Real Assets Portal Login',
  EMAIL_LABEL: 'Email',
  EMAIL_PLACEHOLDER: 'email',
  PASSWORD_LABEL: 'Password',
  PASSWORD_PLACEHOLDER: 'password',
  LOGIN_BUTTON: 'Login',
  NEW_ACCOUNT_QUESTION: "Don't have an account?",
  SIGNUP_BUTTON: 'Sign up for early access',
  INVALID_CREDENTIALS_MSG: 'Invalid credentials',
  FORGOT_PASSWORD: 'Forgot Password?',
  RESET_PASSWORD: 'Reset Password',
  NEW_PASSWORD_LABEL: 'New Password',
  CONFIRM_PASSWORD_LABEL: 'Confirm Password',
  RESET_PASSWORD_BUTTON: 'Reset Password',
  PASSWORD_RESET_EMAIL_SENT:
    'If the email exists, a password reset link will be sent.',
  PASSWORD_RESET_ERROR: 'An error occurred. Please try again.',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match.',
  PASSWORD_RESET_SUCCESS:
    'Your password has been reset successfully. Please log in with your new password.',
};
