export const landingLabels = {
  title: 'Real Assets Portal',
  betaLogin: 'Beta Login',
  betaLoginShortcut: 'L',
  mainHeading: {
    part1: 'Real Assets',
    part2: 'Portfolio Management',
  },
  subHeading: 'Simplify your real estate development portfolio.',
  emailPlaceholder: 'Your email address',
  notifyButton: 'Notify Me',
  consentMessage:
    'I agree to receive updates about the Real Assets Investor Portal. Your email will only be used for these updates, and we will never share or sell your data. You can unsubscribe anytime.',
  toastMessages: {
    consentRequired: 'Please agree to the consent message before signing up.',
    subscribeSuccess: 'You have been subscribed successfully!',
    subscribeError: 'Error: ',
  },
  imageAlt: 'App preview',
};
