import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { sendProjectUpdateNotification } from '../../api/fetchers';
import Toast from '../../components/ui-modals/ToastMessage';

const PostUploadDialog = ({ open, onClose, projectIds }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    console.log('Received projectIds in PostUploadDialog:', projectIds);
  }, [projectIds]);

  const handleSendNotifications = async () => {
    setIsLoading(true);
    try {
      for (const projectId of projectIds) {
        await sendProjectUpdateNotification(projectId, updateMessage);
      }
      onClose('Project update notifications sent successfully!', 'success');
    } catch (error) {
      console.error('Error sending project update notifications:', error);
      onClose(
        'An error occurred while sending the project update notifications. Please try again.',
        'error',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose('Project notifications were not sent.', 'info');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Upload Successful</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Would you like to send notifications to investors about this update?
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="update-message"
          label="Update Message"
          type="text"
          fullWidth
          variant="standard"
          value={updateMessage}
          onChange={e => setUpdateMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          No, Don&apos;t Send
        </Button>
        <Button
          onClick={handleSendNotifications}
          disabled={isLoading || !updateMessage.trim()}
        >
          {isLoading ? 'Sending...' : 'Yes, Send Notifications'}
        </Button>
      </DialogActions>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={() => setToast({ ...toast, open: false })}
      />
    </Dialog>
  );
};

export default PostUploadDialog;
