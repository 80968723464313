import {
  AdminPanelSettingsTwoTone,
  AnalyticsTwoTone,
  AutoAwesomeMosaicTwoTone,
  BadgeTwoTone,
  ExpandLess,
  ExpandMore,
  FileDownloadTwoTone,
  OtherHousesTwoTone,
  PublishTwoTone,
  SettingsSuggestTwoTone,
  SourceTwoTone,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { app_paths } from '../../App';
import { useAdminMode } from '../../contexts/AdminModeContext';
import { useSidebar } from '../../contexts/SidebarContext';
import { menu_labels } from '../../labels/menu_labels';
import LogoutButton from '../buttons/LogoutButton';
import UserMenuAdmin from '../ui-modals/UserMenuAdmin';

const Sidebar = ({
  onThemeSwitch,
  currentTheme,
  selectedUser,
  selectedAccount,
  onUserChange,
  open,
  onToggle,
}) => {
  const location = useLocation();
  const [adminOpen, setAdminOpen] = useState(true);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const { adminModeActive, setAdminModeActive } = useAdminMode();
  const { isOpen, toggleSidebar } = useSidebar();

  useEffect(() => {
    if (!isOpen) {
      setAdminOpen(true);
    }
  }, [isOpen]);

  const isAdmin = selectedUser?.role === 'Admin' || adminModeActive;

  const handleUserMenuClick = event => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleUserIconClick = event => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleAdminClick = () => {
    if (!isOpen) {
      toggleSidebar();
      setAdminOpen(true);
    } else {
      setAdminOpen(!adminOpen);
    }
  };

  const handleToggle = () => {
    toggleSidebar();
  };

  const isActive = path => location.pathname === path;

  const MenuItem = ({ to, icon, label, isChild = false }) => (
    <Tooltip title={label} placement="right" arrow disableHoverListener={open}>
      <Link
        to={to}
        className={`flex items-center space-x-3 p-2 rounded ${
          isActive(to)
            ? 'bg-blue-500 text-white'
            : 'hover:bg-gray-200 dark:hover:bg-gray-700'
        } ${isChild ? 'ml-4 text-gray-600 dark:text-gray-400' : ''}`}
      >
        {icon}
        {open && <span className="text-sm">{label}</span>}
      </Link>
    </Tooltip>
  );

  return (
    <div
      className={`h-screen fixed top-0 left-0 ${
        isOpen ? 'w-64' : 'w-16'
      } bg-white dark:bg-gray-900 border-r border-gray-300 dark:border-gray-700 text-gray-800 dark:text-white flex flex-col overflow-hidden transition-width duration-300`}
    >
      <div className="p-3 border-gray-300 dark:border-gray-700 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          {open && (
            <span className="font-bold truncate">Tandem Investor Platform</span>
          )}
        </div>
        <Tooltip title={open ? '' : 'Expand sidebar'} placement="right" arrow>
          <button
            onClick={handleToggle}
            className={`p-1 ${open ? '' : 'w-10 h-10 flex items-center justify-center'}`}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </button>
        </Tooltip>
      </div>

      <nav className="flex-grow overflow-y-auto">
        <div className="p-3">
          {open && (
            <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2 ml-2">
              Main Menu
            </h3>
          )}
          <ul className="space-y-1">
            <MenuItem
              to={app_paths.HOME_PATH}
              icon={<OtherHousesTwoTone className="w-5 h-5" />}
              label="Home"
            />
            <MenuItem
              to={app_paths.PORTFOLIO_DASHBOARD_PATH}
              icon={<AutoAwesomeMosaicTwoTone className="w-5 h-5" />}
              label={menu_labels.PORTFOLIO_DASHBOARD_TITLE}
            />
            <MenuItem
              to={app_paths.PROJECTS_DASHBOARD_PATH}
              icon={<AnalyticsTwoTone className="w-5 h-5" />}
              label={menu_labels.PROJECTS_DASHBOARD_TITLE}
            />
            <MenuItem
              to={app_paths.REPORTS_PATH}
              icon={<FileDownloadTwoTone className="w-5 h-5" />}
              label={menu_labels.REPORTS_PAGE_TITLE}
            />
          </ul>
        </div>

        {isAdmin && (
          <div className="p-3 border-t border-gray-300 dark:border-gray-700">
            {open && (
              <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2 ml-2">
                Admin Tools
              </h3>
            )}
            <Tooltip title="Admin Tools" placement="right" arrow>
              <button
                onClick={handleAdminClick}
                className={`flex items-center justify-between w-full p-2 rounded ${
                  isActive(app_paths.ADMIN_PATH)
                    ? 'bg-blue-500 text-white'
                    : 'hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-800 dark:hover:text-white'
                }`}
              >
                <div className="flex items-center space-x-3">
                  <AdminPanelSettingsTwoTone className="w-5 h-5" />
                  {open && <span className="text-sm">Admin</span>}
                </div>
                {open && (adminOpen ? <ExpandLess /> : <ExpandMore />)}
              </button>
            </Tooltip>
            {isAdmin && open && adminOpen && (
              <ul className="mt-1 space-y-1">
                <MenuItem
                  to={app_paths.PROJECTS_PATH}
                  icon={<SourceTwoTone className="w-5 h-5" />}
                  label={menu_labels.PROJECTS_PAGE_TITLE}
                  isChild
                />
                <MenuItem
                  to={app_paths.UPLOAD_PATH}
                  icon={<PublishTwoTone className="w-5 h-5" />}
                  label={menu_labels.UPLOAD_PAGE_TITLE}
                  isChild
                />
                <MenuItem
                  to={app_paths.SETTINGS_PATH}
                  icon={<SettingsSuggestTwoTone className="w-5 h-5" />}
                  label={menu_labels.SETTINGS_PAGE_TITLE}
                  isChild
                />
              </ul>
            )}
          </div>
        )}
      </nav>

      <div className="mt-auto p-2 border-t border-gray-300 dark:border-gray-700">
        <LogoutButton label="Logout" collapsed={!open} />
      </div>

      <div className="p-3 border-t border-gray-300 dark:border-gray-700">
        <Tooltip
          title={`${selectedUser?.username || 'User'} (${selectedAccount?.name || 'Account'})`}
          placement="right"
          arrow
          disableHoverListener={open}
        >
          <button
            onClick={handleUserIconClick}
            className="w-full flex items-center space-x-3 p-2 rounded hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-800 dark:hover:text-white"
          >
            <BadgeTwoTone className="w-5 h-5" />
            {open && (
              <div className="flex-grow text-left">
                <p className="text-sm font-bold truncate">
                  {selectedUser?.username || 'Loading...'}
                </p>
                <span className="text-xs text-gray-500 dark:text-gray-400 truncate">
                  {selectedAccount?.name || 'Loading...'}
                </span>
              </div>
            )}
            {open && isAdmin && <ExpandMore className="w-5 h-5" />}
          </button>
        </Tooltip>
      </div>

      {isAdmin && (
        <UserMenuAdmin
          anchorEl={userMenuAnchorEl}
          open={Boolean(userMenuAnchorEl)}
          onClose={handleUserMenuClose}
          onUserChange={onUserChange}
          isAdmin={isAdmin}
          currentUser={selectedUser}
        />
      )}
    </div>
  );
};

export default Sidebar;
