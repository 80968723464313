import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { INVESTMENT_METRIC_SLUGS } from '../../shared/constants';
import '../../styles/index.css';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  fontSize: '0.75rem',
  padding: theme.spacing(0.5, 1),
  minWidth: '32px',
  height: '36px',
}));

const KpiFilterDropdown = ({ kpis, selectedKPIs = [], onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event, kpiSlug) => {
    event.preventDefault();
    event.stopPropagation();
    const newSelectedKPIs = selectedKPIs.includes(kpiSlug)
      ? selectedKPIs.filter(slug => slug !== kpiSlug)
      : [...selectedKPIs, kpiSlug];
    onFilterChange(newSelectedKPIs);
  };

  const groupedKpis = kpis.reduce((acc, kpi) => {
    let type;
    if (INVESTMENT_METRIC_SLUGS.includes(kpi.slug)) {
      type = 'Investment Metrics';
    } else if (kpi.slug.startsWith('PR_')) {
      type = 'Project Metrics';
    } else {
      type = 'Asset Management Metrics';
    }

    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(kpi);
    return acc;
  }, {});

  // Ensure a specific order for the groups
  const orderedGroups = [
    'Investment Metrics',
    'Project Metrics',
    'Asset Management Metrics',
  ];

  return (
    <>
      <StyledButton
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        KPI Filters
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'var(--bg-secondary)',
            color: 'var(--foreground)',
            maxHeight: '400px',
          },
        }}
      >
        {orderedGroups.map(groupName => (
          <div key={groupName}>
            <Typography
              variant="subtitle1"
              sx={{ px: 2, py: 1, fontWeight: 'bold' }}
            >
              {groupName}
            </Typography>
            {(groupedKpis[groupName] || []).map(kpi => (
              <MenuItem
                key={kpi.slug}
                onClick={event => handleToggle(event, kpi.slug)}
                dense
              >
                <Checkbox
                  checked={selectedKPIs.includes(kpi.slug)}
                  onChange={event => handleToggle(event, kpi.slug)}
                  onClick={event => event.stopPropagation()}
                  sx={{
                    color: 'var(--foreground)',
                    '&.Mui-checked': {
                      color: 'var(--accent-color)',
                    },
                  }}
                />
                <ListItemText
                  primary={kpi.name}
                  sx={{ color: 'var(--foreground)' }}
                />
              </MenuItem>
            ))}
          </div>
        ))}
      </Menu>
    </>
  );
};

export default KpiFilterDropdown;
